import { Add, ArrowDownward, ArrowUpward, Close, Delete, Save } from "@mui/icons-material";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchDelete, fetchGet, LayoutContext, newGuid } from "wcz-layout";
import TemplateTask from "../../../models/TemplateTask";
import TemplateTaskUser from "../../../models/TemplateTaskUser";
import User from "../../../models/User";
import { apiUrl, peoplesoftUrl } from "../../../utils/BaseUrl";

interface FormDialogProps {
    open: boolean,
    onClose: () => void,
    tasks: TemplateTask[],
    setTasks: (tasks: TemplateTask[]) => void,
    onSubmit: () => void,
    title: string,
    refetch: () => void,
    templateId?: string
}

export default function TemplateDialog(props: FormDialogProps) {
    const { snackbar, t } = useContext(LayoutContext);
    const [users, setUsers] = useState<TemplateTaskUser[]>([]);

    useQuery<User[]>(["users"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=companyCode==530;status==Active`, signal), {
        enabled: props.open,
        onSuccess: (data) => {
            const transformedData: TemplateTaskUser[] = data.map(u => ({ id: "", name: u.nameTitleCase, employeeId: u.employeeId }));
            setUsers(transformedData)
        }
    });

    const { mutate: remove } = useMutation((id: string) => fetchDelete(apiUrl + "/v1/template/" + id), {
        onSuccess: () => { props.onClose(); snackbar({ message: t("Deleted"), severity: 'success' }); props.refetch(); },
    });

    const handleOnTextChange = (value: string, index: number) => {
        const tasks = props.tasks;
        tasks[index].text = value;
        props.setTasks(tasks);
    }

    const handleOnTextEnChange = (value: string, index: number) => {
        const tasks = props.tasks;
        tasks[index].textEn = value;
        props.setTasks(tasks);
    }

    const handleOnTaskOmittedUsersChange = (values: TemplateTaskUser[], index: number) => {
        const tasks = props.tasks;
        tasks[index].omittedUsers = values.map(value => ({ ...value, id: newGuid() }));
        props.setTasks(tasks);
    }

    const handleOnIndexUp = (index: number) => {
        const tasks = props.tasks;

        const lowerElement = tasks[index - 1];
        const element = tasks[index];

        tasks[index - 1] = element;
        tasks[index] = lowerElement;

        props.setTasks(tasks);
    }

    const handleOnIndexDown = (index: number) => {
        const tasks = props.tasks;

        const higherElement = tasks[index + 1];
        const element = tasks[index];

        tasks[index + 1] = element;
        tasks[index] = higherElement;

        props.setTasks(tasks);
    }

    const handleOnDelete = (index: number) => {
        const tasks = props.tasks;
        tasks.splice(index, 1);
        props.setTasks(tasks);
    }

    const handleOnAdd = () => {
        const tasks = props.tasks;
        const newTask: TemplateTask = { text: "", textEn: "", taskIndex: 0, omittedUsers: [] };
        tasks.push(newTask);
        props.setTasks(tasks);
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
            <Grid container justifyContent="space-between">
                <Grid item xs={10} sm={11}>
                    <DialogTitle sx={{ pb: 0 }}>{props.title}</DialogTitle>
                </Grid>

                <Grid item xs={2} sm={1} sx={{ p: 1, textAlign: 'right' }}>
                    <IconButton onClick={props.onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                {props.tasks?.map((task, index) =>
                    <Grid container key={task.id} sx={{ my: 1 }}>
                        <Grid item md={1} xs={2} sx={{ my: 'auto' }}>
                            {!!index &&
                                <IconButton onClick={() => handleOnIndexUp(index)}>
                                    <ArrowUpward />
                                </IconButton>
                            }

                            {index + 1 !== props.tasks.length &&
                                <IconButton onClick={() => handleOnIndexDown(index)}>
                                    <ArrowDownward />
                                </IconButton>
                            }
                        </Grid>

                        <Grid item md={11} xs={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField margin="dense" label={t("Task")} fullWidth value={task.text} multiline required variant="standard"
                                        onChange={(e) => handleOnTextChange(e.target.value, index)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField margin="dense" label={`${t("Task")} EN`} fullWidth value={task.textEn} multiline required variant="standard"
                                        onChange={(e) => handleOnTextEnChange(e.target.value, index)} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Autocomplete multiple options={users} value={task.omittedUsers} onChange={(e, value) => handleOnTaskOmittedUsersChange(value, index)} getOptionLabel={o => `${o.name} (${o.employeeId})`}
                                        renderInput={(params) => (<TextField {...params} variant="standard" label={t("OmitUser")} />)} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid md={12} xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                            {index + 1 === props.tasks.length &&
                                <IconButton onClick={() => handleOnAdd()}>
                                    <Add />
                                </IconButton>
                            }

                            {props.tasks.length !== 1 &&
                                <IconButton onClick={() => handleOnDelete(index)}>
                                    <Delete />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        {props.templateId && <Button onClick={() => remove(props.templateId!)} startIcon={<Delete />}>{t("Delete")}</Button>}
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => props.onSubmit()} startIcon={<Save />}>{t("Save")}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}